/** @jsx jsx */
import React from 'react';
import { FormattedMessage } from "gatsby-plugin-intl"
import { jsx } from 'theme-ui';
import SVG from './svg';

type ServiceCardProps = {
    children: React.ReactNode;
    bg: string;
    header: string;
    icon?: string;
    icons?: string[];
    fallback?: string;
};

const ServiceCard = ({ children, bg, header, icon, icons, fallback }: ServiceCardProps) => (
    <div
        sx={{
            width: '100%',
            boxShadow: '0 6px 24px rgba(0, 0, 0, .3)',
            position: 'relative',
            textDecoration: 'none',
            borderRadius: 'lg',
            px: 4,
            py: [2, 3],
            color: '#414141',
            background: bg || 'none',
            transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important',
            '&:hover': {
                color: '#000',
                transform: 'translateY(-5px)',
                boxShadow: 'xl',
            },
        }}
    >
        <div
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                paddingBottom: '1rem',
                paddingTop: '1rem',
            }}
        >
            {icon && <SVG icon={icon} width={24} />}
            {/* {icon && <img src={icon} style={{ width: '80px' }} />} */}
            <div
                style={{
                    display: 'flex',
                }}
            >
                {icons && icons.map((ico) => <SVG key={ico} icon={ico} width={ico === 'plus' ? 10 : 24} />)}
            </div>

            <h3
                sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    letterSpacing: 'wide',
                    pt: 1,
                    fontSize: ['1rem', '1.5rem'],
                    fontWeight: 'bold',
                    lineHeight: 1,
                }}
            >
                <FormattedMessage id={`app.service.name.${header}`} defaultMessage={fallback || ''} />
            </h3>

            <div
                sx={{
                    pt: 1,
                    fontSize: ['.8rem', '.9rem'],
                    lineHeight: 1.8,
                    color: '#000',
                    textAlign: 'justify',
                }}
            >
                {children}
            </div>
        </div>
    </div>
);

export default ServiceCard;
