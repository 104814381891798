import React from "react";
import IntroCard from "../components/intro-card";
import ServiceCard from "../components/service-card";

const components = {
  // eslint-disable-next-line react/display-name
  IntroCard: ({ link, title, bg, children }) => (
    <IntroCard link={link} title={title} bg={bg}>
      {children}
    </IntroCard>
  ),
  ServiceCard: (props) => (
    <ServiceCard {...props} />
  ),
};

export default components;
