/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

type ProjectCardProps = {
    children: React.ReactNode;
    bg: string;
};

const IntroCard = ({ children, bg }: ProjectCardProps) => (
    <div
        sx={{
            width: '100%',
            boxShadow: '0 6px 24px rgba(0, 0, 0, .6)',
            position: 'relative',
            textDecoration: 'none',
            borderRadius: 'lg',
            px: [4],
            py: [2, 3],
            color: '#414141',
            background: bg || 'none',
            transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important',
            '&:hover': {
                color: '#000',
                transform: 'translateY(-5px)',
                boxShadow: 'xl',
            },
        }}
    >
        <div>{children}</div>
        <div
            sx={{
                textTransform: 'uppercase',
                letterSpacing: 'wide',
                pt: 4,
                fontSize: [4, 5],
                fontWeight: 'medium',
                lineHeight: 1,
            }}
        ></div>
    </div>
);

export default IntroCard;
